import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, Redirect } from "react-router-dom"
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import "./index.css"
import { toast, ToastContainer } from "react-toastify"

import baseURL from "../../utils"
import { FiPackage } from "react-icons/fi"
import { MdOutlineInventory } from "react-icons/md"
import { AiOutlineArrowUp } from "react-icons/ai"

const Dashboard = () => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState({})
  const [total, setTotal] = useState("")
  const [users, setUsers] = useState([])
  const [redirect, setRedirect] = useState("")
  const [copied, setCopied] = useState(false)
  const [packages, setPackages] = useState([])

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-groups`,
          options
        )
        const packages = await response.json()
        console.log(packages)
        console.log(packages.data.package_groups)
        setPackages(packages.data.package_groups)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        // window.location.href = '/login'
      }
    }

    fetchPackage()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dilivva | Swifthub Dashboard</title>
        </MetaTags>
        <Container fluid>
          <ToastContainer />
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="dashboard-head">Dashboard</h2>
              </Col>
            </Row>
            {loading ? (
              <>
                <Row className="">
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <Card className="card-height">
                          <CardBody>
                            <div className="col-8 skeleton skeleton-p mt-2 mb-4"></div>
                            <div className="col-3 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="card-height">
                          <CardBody>
                            <div className="col-8 skeleton skeleton-p mt-2 mb-4"></div>
                            <div className="col-3 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="card-height">
                          <CardBody>
                            <div className="col-8 skeleton skeleton-p mt-2 mb-4"></div>
                            <div className="col-3 skeleton skeleton-head p-3 mt-2 mb-4"></div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12}>
                            <Card className="card-height">
                              <CardBody>
                                <div className="col-md-5 skeleton skeleton-p mt-2 mb-4"></div>
                                <div className="col-md-3 skeleton skeleton-head p-2 mt-2 mb-4"></div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} className="mt-4">
                    <Card className="card-height">
                      <Link to="#">
                        <CardBody style={{ minHeight: "308px" }}>
                          <div className="col-md-6">
                            <div className="col-md-5 skeleton skeleton-head p-2 mt-2"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="skeleton skeleton-head p-2 mt-2"></div>
                            <div className="skeleton skeleton-head p-2 mt-2"></div>
                          </div>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <Link to="/orders">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Incoming Packages</p>
                              <span className="mt-4 incoming">
                                <FiPackage size={25} />
                              </span>
                              <h4 className="dashboard-head">{total}</h4>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md={4}>
                        <Link to="/inventory">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Accepted Packages</p>
                              <span className="mt-4 accepted">
                                <MdOutlineInventory size={20} className="" />
                              </span>
                              <h4 className="dashboard-head">{total}</h4>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                      <Col md={4}>
                        <Link to="/outgoing-orders">
                          <Card className="card-height">
                            <CardBody>
                              <p className="head">Outgoing Packages</p>
                              <h4 className="dashboard-head">
                                {profile?.referrals_completed_order_count}
                              </h4>
                              <span className="mt-4 outgoing">
                                <AiOutlineArrowUp size={20} className="" />
                              </span>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card className="card-height">
                          <CardBody style={{ minHeight: "304px" }}>
                            <div>
                              <h5>Bagged Packages</h5>
                              {packages?.length !== 0 ? (
                                <>
                                  <div className="table-responsive">
                                    <Table className="table responsive table-hover mb-0">
                                      <thead>
                                        <tr>
                                          <th>Final Hub</th>
                                          <th>Location</th>
                                          <th>Items</th>
                                          <th>Created</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {packages
                                          ?.slice(0, 2)
                                          ?.map((item, index) => {
                                            const {
                                              uuid,
                                              status,
                                              delivery_hub,
                                              package_movements,
                                              updated_at,
                                            } = item
                                            return (
                                              <>
                                                <tr
                                                  key={index}
                                                  onClick={() =>
                                                    setRedirect(
                                                      `grouped-packages/${uuid}`
                                                    )
                                                  }
                                                >
                                                  <td>
                                                    {delivery_hub?.address}
                                                  </td>
                                                  <td>
                                                    {
                                                      delivery_hub?.location
                                                        ?.name
                                                    }
                                                  </td>
                                                  <td>
                                                    {package_movements.length}
                                                  </td>
                                                  <td>{updated_at}</td>
                                                  <td
                                                    style={{
                                                      color:
                                                        status === "accepted"
                                                          ? "#29CC97"
                                                          : status ===
                                                            "completed"
                                                          ? "#25b182"
                                                          : status ===
                                                            "cancelled"
                                                          ? "#DC1515"
                                                          : "#FEC400",
                                                    }}
                                                  >
                                                    {status}
                                                  </td>
                                                </tr>
                                              </>
                                            )
                                          })}
                                      </tbody>
                                    </Table>
                                    {packages.length > 2 && (
                                      <Link
                                        className="text-dark float-end px-3 pt-3"
                                        to="/grouped-packages"
                                      >
                                        <h6>see more...</h6>
                                      </Link>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center align-items-center my-5">
                                  <h6>Oops, No orders</h6>
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Card className="card-height">
                      <CardBody style={{ minHeight: "470px" }}>
                        <h5>Summary</h5>
                        <div className="p-3"></div>
                        <div></div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
