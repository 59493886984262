import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table } from "reactstrap"
import "./orders.css"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, Redirect } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs"
import baseURL from "../../utils"

const OutgoingOrdersDetails = props => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  // Order Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-movements/${props?.match?.params?.id}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data, "orders")
        setLoading(false)
        setOrders(orders.data)
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | Swifthub Admin</title>
        </MetaTags>
        <ToastContainer />
        {loading ? (
          <div className="container">
            <div className="row mx-md-3">
              <div className="col-md-2 skeleton skeleton-head p-3 mt-1 mb-3"></div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <Link to="/outgoing-orders" className="mb-4 mx-md-4 btn btn-secondary">
                <BsArrowLeft /> <span className="mx-2">back to orders</span>
              </Link>
            </div>
            <div className="container">
              <div className="row mx-md-3">
                <div className="card  ">
                  <div className="card-body">
                    <h5>Order Details</h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-6">
                        <span className="price-head text-green">
                          Pickup Address
                        </span>
                        <h6 className="details">
                          {orders?.order?.pickup_address}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <span className="price-head text-green">
                          Destination Address
                        </span>
                        <h6 className="details">
                          {orders?.order?.destination_address}
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <span className="price-head text-green">Final Hub</span>
                        <h6 className="details">
                          {orders?.final_hub
                            ? orders?.final_hub?.address
                            : "None"}
                        </h6>
                      </div>

                      <div className="col-md-3">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">{orders?.order?.created_at}</h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Reference</span>
                        <h6 className="details">{orders?.order?.reference}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>Package Details</h5>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <span className="price-head text-green">Label</span>
                        <h6 className="details">
                          {orders?.order?.package?.label}
                        </h6>
                      </div>
                      <div className="col">
                        <span className="price-head text-green">Reference</span>
                        <h6 className="details">
                          {orders?.order?.package?.reference}
                        </h6>
                      </div>
                      <div className="col">
                        <span className="price-head text-green">Size</span>
                        <h6 className="details">
                          {orders?.order?.package?.size}
                        </h6>
                      </div>
                      {orders?.order?.package?.note && (
                        <div className="col">
                          <span className="price-head text-green">Note</span>
                          <h6 className="details">
                            {orders?.order?.package?.note}
                          </h6>
                        </div>
                      )}
                      <div className="col">
                        <span className="price-head text-green">Fragile</span>
                        <h6 className="details">
                          {orders?.order?.package?.is_fragile === 0
                            ? "No"
                            : "Yes"}
                        </h6>
                      </div>
                    </div>
                    <div className="row p-2">
                      <img
                        src={orders?.order?.package?.images[0]}
                        className="img-fluid"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orders?.order?.pickup_contact && (
              <>
                <div className="container">
                  <div className="row mx-md-3">
                    <div className="card">
                      <div className="card-body">
                        <h5>Pickup Contact</h5>
                        <hr />
                        <div className="row">
                          <div className="col">
                            <span className="price-head text-green">Name</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.name}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">Email</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.email}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">Phone</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.phone}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">
                              Date Created
                            </span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.created_at}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>Sender</h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-3">
                        <span className="price-head text-green">Name</span>
                        <h6 className="details">
                          {orders?.order?.user?.full_name}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">
                          {orders?.order?.user?.email}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Phone</span>
                        <h6 className="details">
                          {orders?.order?.user?.phone}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">
                          {orders?.order?.user?.created_at}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>
                      Recipient(
                      {orders?.order?.recipient?.is_user === true
                        ? "user"
                        : "Not a user"}
                      )
                    </h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-3">
                        <span className="price-head text-green">Name</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.name}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.email}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Phone</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.phone}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">
                          {orders?.order?.recipient?.created_at}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orders?.order?.alternate_recipient && (
              <>
                <div className="container">
                  <div className="row mx-md-3">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="mb-3">Alternate Recipient</h5>
                        <hr />
                        <div className="row">
                          <div className="col-md-3">
                            <span className="price-head text-green">Name</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.name}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">Phone</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.phone}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">
                              Address
                            </span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.address}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">Notes</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.notes}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">LGA</span>
                            <h6 className="details">
                              {
                                orders?.order?.alternate_recipient
                                  ?.local_government
                              }
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">State</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.state}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default OutgoingOrdersDetails
