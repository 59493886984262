import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table } from "reactstrap"
import "./orders.css"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, Redirect } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs"
import baseURL from "../../utils"

const OrdersDetails = props => {
  const [orders, setOrders] = useState([])
  const [singleHub, setSingleHub] = useState({})
  const [loading, setLoading] = useState(true)
  const [hubs, setHubs] = useState([])
  const [buttonLoad, setButtonLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [disableButtonStatus, setDisableButtonStatus] = useState(true)
  const [search, setSearch] = useState("")
  const [redirect, setRedirect] = useState("")
  const [selectStatus, setSelectStatus] = useState("")
  const [selectHub, setSelectHub] = useState("")
  const [address, setAddress] = useState("")

  const showModal = singleHub => {
    setSingleHub(singleHub)
  }

  // Fetch Delivery Hubs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/delivery-hubs?address=${address}`,
          options
        )
        const hubs = await response.json()
        console.log(hubs.data.delivery_hubs)
        setHubs(hubs.data.delivery_hubs)
        console.log(hubs)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [address])

  // Order Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-movements/${props?.match?.params?.id}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data, "orders")
        setLoading(false)
        setOrders(orders.data)
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // transfer package
  const transferPackage = async (orderId, destinationHubId) => {
    console.log(orderId, destinationHubId)
    if (confirm("Are you sure you want to transfer the package?")) {
      // do stuff
      const dataForm = new FormData()
      dataForm.append("to_destination_address", 0)
      dataForm.append("destination_hub_id", destinationHubId)

      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            accept: "application/json",
            // "Content-Type": "multipart/form-data",
          }),

          body: dataForm,
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-movements/${orderId}/transfer`,
          options
        )
        const data = await response.json()
        if (data.status == "success") {
          toast.success(data["message"])
          setTimeout(() => {
            location.reload()
          }, 3000)
        } else if (data.status == "error") {
          toast.error(data["message"])
        }
      } catch (error) {
        // do what
        console.log(error.message)
      }
    } else {
      return false
    }
  }

  // Remove hub
  const removehub = async () => {
    if (confirm("Are you sure you want to remove this hub?")) {
      // do stuff
      setButtonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "PUT",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${props?.match?.params?.id}/remove-hub`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/all-orders"
        }, 3000)
      } catch (error) {
        // do what
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      }
    } else {
      return false
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | Swifthub Admin</title>
        </MetaTags>
        <ToastContainer />
        {loading ? (
          <div className="container">
            <div className="row mx-md-3">
              <div className="col-md-2 skeleton skeleton-head p-3 mt-1 mb-3"></div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <Link
                to="/inventory#tab2"
                className="mb-4 mx-md-4 btn btn-secondary"
              >
                <BsArrowLeft /> <span className="mx-2">back to orders</span>
              </Link>
            </div>
            <div className="container">
              <div className="row mx-md-3">
                <div className="card  ">
                  <div className="card-body">
                    <h5>Order Details</h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        <span className="price-head text-green">
                          Pickup Address
                        </span>
                        <h6 className="details">
                          {orders?.order?.pickup_address}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">
                          Recommended Hub
                        </span>
                        <h6 className="details">
                          {orders?.final_hub?.address}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">
                          Destination Address
                        </span>
                        <h6 className="details">
                          {orders?.order?.destination_address}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Final Hub</span>
                        <h6 className="details">
                          {orders?.final_hub
                            ? orders?.final_hub?.address
                            : "None"}
                        </h6>
                      </div>

                      <div className="col-md-4">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">{orders?.order?.created_at}</h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Reference</span>
                        <h6 className="details">{orders?.order?.reference}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>Package Details</h5>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <span className="price-head text-green">Label</span>
                        <h6 className="details">
                          {orders?.order?.package?.label}
                        </h6>
                      </div>
                      <div className="col">
                        <span className="price-head text-green">Reference</span>
                        <h6 className="details">
                          {orders?.order?.package?.reference}
                        </h6>
                      </div>
                      <div className="col">
                        <span className="price-head text-green">Size</span>
                        <h6 className="details">
                          {orders?.order?.package?.size}
                        </h6>
                      </div>
                      {orders?.order?.package?.note && (
                        <div className="col">
                          <span className="price-head text-green">Note</span>
                          <h6 className="details">
                            {orders?.order?.package?.note}
                          </h6>
                        </div>
                      )}
                      <div className="col">
                        <span className="price-head text-green">Fragile</span>
                        <h6 className="details">
                          {orders?.order?.package?.is_fragile === 0
                            ? "No"
                            : "Yes"}
                        </h6>
                      </div>
                    </div>
                    <div className="row p-2">
                      <img
                        src={orders?.order?.package?.images[0]}
                        className="img-fluid"
                        style={{ width: "200px" }}
                        alt="package-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orders?.order?.pickup_contact && (
              <>
                <div className="container">
                  <div className="row mx-md-3">
                    <div className="card">
                      <div className="card-body">
                        <h5>Pickup Contact</h5>
                        <hr />
                        <div className="row">
                          <div className="col">
                            <span className="price-head text-green">Name</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.name}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">Email</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.email}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">Phone</span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.phone}
                            </h6>
                          </div>
                          <div className="col">
                            <span className="price-head text-green">
                              Date Created
                            </span>
                            <h6 className="details">
                              {orders?.order?.pickup_contact?.created_at}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>Sender</h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-3">
                        <span className="price-head text-green">Name</span>
                        <h6 className="details">
                          {orders?.order?.user?.full_name}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">
                          {orders?.order?.user?.email}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Phone</span>
                        <h6 className="details">
                          {orders?.order?.user?.phone}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">
                          {orders?.order?.user?.created_at}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5>
                      Recipient(
                      {orders?.order?.recipient?.is_user === true
                        ? "user"
                        : "Not a user"}
                      )
                    </h5>
                    <hr />
                    <div className="row">
                      <div className="col-md-3">
                        <span className="price-head text-green">Name</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.name}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.email}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">Phone</span>
                        <h6 className="details">
                          {orders?.order?.recipient?.phone}
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <span className="price-head text-green">
                          Date Created
                        </span>
                        <h6 className="details">
                          {orders?.order?.recipient?.created_at}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orders?.order?.alternate_recipient && (
              <>
                <div className="container">
                  <div className="row mx-md-3">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="mb-3">Alternate Recipient</h5>
                        <hr />
                        <div className="row">
                          <div className="col-md-3">
                            <span className="price-head text-green">Name</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.name}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">Phone</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.phone}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">
                              Address
                            </span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.address}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">Notes</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.notes}
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">LGA</span>
                            <h6 className="details">
                              {
                                orders?.order?.alternate_recipient
                                  ?.local_government
                              }
                            </h6>
                          </div>
                          <div className="col-md-3">
                            <span className="price-head text-green">State</span>
                            <h6 className="details">
                              {orders?.order?.alternate_recipient?.state}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div>
              <div className="container">
                <div className="row mx-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-3">Hub</h5>
                      <hr />
                      <div>
                        <p className="details">
                          Transfer Package to recommended hub
                        </p>

                        <button
                          className="btn btn-success"
                          onClick={() =>
                            transferPackage(
                              orders?.uuid,
                              orders?.final_hub?.uuid
                            )
                          }
                        >
                          Transfer Package
                        </button>

                        <p className="m-3">
                          ------------------------- or ---------------------
                        </p>

                        <span
                          className="btn text-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                          onClick={() => {
                            showModal(singleHub)
                          }}
                        >
                          Transfer to another hub instead
                        </span>

                        <div
                          className="modal fade"
                          id="exampleModal2"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-scrollable modal-lg">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Transfer Package To Hub
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <input
                                        type="text"
                                        placeholder="Search hubs by name or email address"
                                        onChange={e =>
                                          setSearch(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <Table className="table responsive table-hover mt-2 mb-0">
                                      <tbody>
                                        {hubs
                                          ?.filter(hub => {
                                            if (search === "") {
                                              return hub
                                            } else if (
                                              hub?.name
                                                ?.toLowerCase()
                                                ?.includes(search.toLowerCase())
                                            ) {
                                              return hub
                                            } else if (
                                              hub?.address
                                                ?.toLowerCase()
                                                ?.includes(
                                                  search?.toLowerCase()
                                                )
                                            ) {
                                              return hub
                                            } else if (
                                              hub?.location?.name
                                                ?.toLowerCase()
                                                ?.includes(
                                                  search?.toLowerCase()
                                                )
                                            ) {
                                              return hub
                                            }
                                          })
                                          ?.map(hub => {
                                            const {
                                              uuid,
                                              name,
                                              address,
                                              location,
                                            } = hub
                                            return (
                                              <>
                                                <tr key={uuid}>
                                                  <td>
                                                    {name} - {location?.name}
                                                    <br />
                                                    <small className="text-success">
                                                      {address}
                                                    </small>
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-outline-warning"
                                                      onClick={() => {
                                                        console.log(
                                                          orders?.uuid,
                                                          uuid
                                                        )
                                                        transferPackage(
                                                          orders?.uuid,
                                                          uuid
                                                        )
                                                      }}
                                                      disabled={disableButton}
                                                    >
                                                      Transfer
                                                    </button>
                                                  </td>
                                                </tr>
                                              </>
                                            )
                                          })}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default OrdersDetails
