import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import swift from "../../assets/images/swifthub.png"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import "./Login.css"

// import images
import logoSm from "../../assets/images/dilivva-logo.png"
import GoogleLogin from "../../components/GoogleLogin"
import baseURL from "../../utils/index"

const url = window.location.origin

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [role, setRole] = useState("hub-manager")
  const [disableButton, setDisbaleButton] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setDisbaleButton(true)
    setButtonLoad(true)
    setRole("hub-manager")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email, password, role }),
    }
    fetch(`${baseURL}/api/v1/login`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status === "success") {
          toast.success(response.message)
          window.location = `${url}/dashboard`
          localStorage.setItem("authUser", response.data.token)
          localStorage.setItem("userId", response.data.user.uuid)
        } else {
          toast.error(response.message)
          setDisbaleButton(false)
          setButtonLoad(false)
        }
      })
      .catch(error => {
        console.log(error.message)
        setDisbaleButton(false)
        setButtonLoad(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Swift Hub Dashboard</title>
      </MetaTags>
      <div className="account-pages pt-sm-5">
        <Container>
          <ToastContainer autoClose={3000} />
          <Row className="">
            <Col md={6} className="d-none d-md-block">
              <img src={swift} className="img-fluid" />
            </Col>
            <Col md={6} className="mt-4">
              <Card className="overflow-hidden mx-md-5">
                <div className="text-primary text-center p-4">
                  <Link to="/" className="logo logo-admin">
                    <img src={logoSm} height="50" alt="logo" />
                  </Link>
                </div>
                <CardBody className="m-3">
                  <div className="login-head">
                    <h3>Login to your dashboard</h3>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Label className="input-label mt-3">EMAIL</Label>
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />

                    <div className="d-flex justify-content-between">
                      <Label className="input-label mt-4">PASSWORD </Label>
                    </div>
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                    <div className="d-flex justify-content-end">
                    <Link
                      to="/forgot-password"
                      className="input-label text-orange mt-2"
                    >
                      Forgot password?
                    </Link>
                    </div>

                    <Row className="mt-4">
                      <Col sm={12} className="text-end">
                        <button
                          className="login-btn w-100"
                          disabled={disableButton}
                          type="submit"
                        >
                          {buttonLoad ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </Col>
                    </Row>
                    {/* <Row className="mt-4">
                      <Col>
                        <GoogleLogin />
                      </Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
