import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../referrals/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Link, Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"
import {
  BsArrowDown,
  BsArrowUp,
  BsEye,
  BsThreeDotsVertical,
} from "react-icons/bs"
import { MdOutlineInventory } from "react-icons/md"
import { Check } from "react-bootstrap-icons"

const Orders = () => {
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [singlePackage, setSinglePackage] = useState({})
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [searchResult, setSearchResult] = useState([])
  const [perPage, setPerPage] = useState(50)
  const [showDate, setShowDate] = useState(false)
  const [packageIds, setPackageIds] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -365),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = singlePackage => {
    setSinglePackage(singlePackage)
  }

  // package Movement

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-movements?perPage=${perPage}&page=${next}&sort=${sort}&type=incoming&date_range=${minDate},${maxDate}`,
          options
        )
        const packages = await response.json()
        console.log(packages)
        console.log(packages.data.package_movements)
        setPackages(packages.data.package_movements)
        setLoading(false)
        setLastPage(packages.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        // window.location.href = '/login'
      }
    }

    fetchPackage()
  }, [perPage, next, sort, minDate, maxDate])

  // accept multiple packages
  const acceptPackage = async id => {
    console.log(id)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          package_movement_ids: id,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/hub-managers/package-movements/confirm-receipt`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        console.log(data)
        setTimeout(() => {
          location.reload()
        }, 3000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setDisableButton(false)
      }
    } catch (error) {
      // do what
      toast.error(error.message)
    } finally {
      setDisableButton(false)
    }
  }

  // Search orders
  const searchOrder = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/search`,
        options
      )
      const data = await response.json()
      setSearchResult(data["data"]["orders"])
      if (data.status == "success") {
        console.log(data["message"])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Incoming Packages | Dilivva Swifthub Dashboard</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Incoming Packages</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search orders by name or reference"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={() => searchOrder()}
            />
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { reference, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/order/" + uuid)}
                  >
                    {reference}
                  </button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer autoClose={3000} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Row className="mt-2">
                        <Col md={9}>
                          <h4 className="title-header">Incoming Packages</h4>
                          <p>Packages that are coming into the hub</p>
                          {showAccept && (
                            <div className="mt-2">
                              <button
                                className="btn btn-success"
                                onClick={() => acceptPackage(packageIds)}
                                disabled={disableButton}
                              >
                                Accept Multiple Packages
                              </button>
                            </div>
                          )}
                        </Col>
                        {packages?.length !== 0 && (
                          <>
                            <Col md={1} xs={8}>
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={perPage}
                                onChange={e => {
                                  setLoading(true)
                                  setPerPage(e.target.value)
                                }}
                              >
                                <option defaultValue>50</option>
                                <option defaultValue="25">25</option>
                                <option defaultValue="10">10</option>
                                <option defaultValue="5">5</option>
                              </select>
                            </Col>
                            <Col md={2} xs={8}>
                              <Row className="">
                                <Col>
                                  <FaArrowUp
                                    className="sort"
                                    onClick={() => setSort("asc")}
                                  />{" "}
                                  <FaArrowDown
                                    className="sort"
                                    onClick={() => setSort("desc")}
                                  />{" "}
                                  Sort
                                </Col>
                                <Col
                                  className="sort"
                                  onClick={() => setShowDate(!showDate)}
                                >
                                  <FaFilter /> Filter
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                      </Row>
                      {packages?.length !== 0 ? (
                        <>
                          <Row>
                            <Col>
                              {showDate && (
                                <DateRangePicker
                                  onChange={item =>
                                    setDateRange([item.selection])
                                  }
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={dateRange}
                                  direction="horizontal"
                                />
                              )}
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <Table className="table responsive table-hover mt-2 mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    {/* <div className="form-check d-flex">
                                      <input
                                        type="checkbox"
                                        onChange={e => {
                                          setCheckAll(!checkAll)
                                        }}
                                      />
                                      <label htmlFor="" className="fw-bold">
                                        Select All
                                      </label>
                                    </div> */}
                                    Select
                                  </th>
                                  <th>Package</th>
                                  <th>Pickup Address</th>
                                  <th>Final Hub</th>
                                  <th>Created</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages?.map((item, index) => {
                                  const { uuid, order, final_hub, updated_at } =
                                    item
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              value={uuid}
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  let eachId = packageIds
                                                  eachId.push(e.target.value)
                                                  setPackageIds(eachId)
                                                  packageIds.length > 1 &&
                                                    setShowAccept(true)
                                                } else {
                                                  let eachId = packageIds
                                                  eachId.pop(e.target.value)
                                                  packageIds.length < 2 &&
                                                    setShowAccept(false)

                                                  setPackageIds(eachId)
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <img
                                            src={order?.package?.images[0]}
                                            alt="package"
                                            className="img-fluid rounded"
                                            width={50}
                                          />
                                          <small style={{ marginLeft: "5px" }}>
                                            {order?.package?.label}
                                          </small>
                                        </td>
                                        <td onClick={e => e.stopPropagation()}>
                                          {order?.pickup_address}
                                        </td>
                                        <td>
                                          {final_hub
                                            ? final_hub?.address
                                            : "None"}
                                        </td>
                                        <td>{updated_at}</td>
                                        <td>
                                          <button
                                            className="badge bg-success p-2 border-0"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => {
                                              showModal(item)
                                            }}
                                          >
                                            view
                                          </button>
                                        </td>
                                      </tr>
                                      {/* Modal */}
                                      <div
                                        className="modal fade"
                                        id="exampleModal"
                                        tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog modal-xl rounded">
                                          <div className="modal-content px-4 border-0">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                {
                                                  singlePackage?.order?.package
                                                    ?.label
                                                }
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="row p-2 mb-3">
                                                <img
                                                  src={
                                                    singlePackage?.order
                                                      ?.package?.images[0]
                                                  }
                                                  className="img-fluid"
                                                  style={{ width: "400px" }}
                                                  alt="package-img"
                                                />
                                              </div>
                                              <div className="row">
                                                <div className="col-md-2">
                                                  <span className="price-head text-green">
                                                    Label
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.package?.label
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col">
                                                  <span className="price-head text-green">
                                                    Reference
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.package?.reference
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col">
                                                  <span className="price-head text-green">
                                                    Size
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.package?.size
                                                    }
                                                  </h6>
                                                </div>
                                                {singlePackage?.order?.package
                                                  ?.note && (
                                                  <div className="col">
                                                    <span className="price-head text-green">
                                                      Note
                                                    </span>
                                                    <h6 className="details">
                                                      {
                                                        singlePackage?.order
                                                          ?.package?.note
                                                      }
                                                    </h6>
                                                  </div>
                                                )}
                                                <div className="col">
                                                  <span className="price-head text-green">
                                                    Fragile
                                                  </span>
                                                  <h6 className="details">
                                                    {singlePackage?.order
                                                      ?.package?.is_fragile ===
                                                    0
                                                      ? "No"
                                                      : "Yes"}
                                                  </h6>
                                                </div>
                                              </div>

                                              <h5 className="mt-5">
                                                Order Details
                                              </h5>
                                              <hr />
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Pickup Address
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.pickup_address
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Destination Address
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.destination_address
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Recommended Hub
                                                  </span>
                                                  <h6 className="details">
                                                    {singlePackage?.recommended_hub
                                                      ? singlePackage
                                                          ?.recommended_hub
                                                          ?.address
                                                      : "None"}
                                                  </h6>
                                                </div>
                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Final Hub
                                                  </span>
                                                  <h6 className="details">
                                                    {singlePackage?.final_hub
                                                      ? singlePackage?.final_hub
                                                          ?.address
                                                      : "None"}
                                                  </h6>
                                                </div>

                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Date Created
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.created_at
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-4">
                                                  <span className="price-head text-green">
                                                    Reference
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.reference
                                                    }
                                                  </h6>
                                                </div>
                                              </div>

                                              {singlePackage?.order
                                                ?.pickup_contact && (
                                                <>
                                                  <div className="row mt-5">
                                                    <h5>Pickup Contact</h5>
                                                    <hr />
                                                    <div className="row">
                                                      <div className="col">
                                                        <span className="price-head text-green">
                                                          Name
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.pickup_contact
                                                              ?.name
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col">
                                                        <span className="price-head text-green">
                                                          Email
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.pickup_contact
                                                              ?.email
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col">
                                                        <span className="price-head text-green">
                                                          Phone
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.pickup_contact
                                                              ?.phone
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col">
                                                        <span className="price-head text-green">
                                                          Date Created
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.pickup_contact
                                                              ?.created_at
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}

                                              <h5 className="mt-5">Sender</h5>
                                              <hr />
                                              <div className="row">
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Name
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order?.user
                                                        ?.full_name
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Email
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order?.user
                                                        ?.email
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Phone
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order?.user
                                                        ?.phone
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Date Created
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order?.user
                                                        ?.created_at
                                                    }
                                                  </h6>
                                                </div>
                                              </div>

                                              <h5 className="mt-5">
                                                Recipient(
                                                {singlePackage?.order?.recipient
                                                  ?.is_user === true
                                                  ? "sender"
                                                  : "Not a sender"}
                                                )
                                              </h5>
                                              <hr />
                                              <div className="row">
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Name
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.recipient?.name
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Email
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.recipient?.email
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Phone
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.recipient?.phone
                                                    }
                                                  </h6>
                                                </div>
                                                <div className="col-md-3">
                                                  <span className="price-head text-green">
                                                    Date Created
                                                  </span>
                                                  <h6 className="details">
                                                    {
                                                      singlePackage?.order
                                                        ?.recipient?.created_at
                                                    }
                                                  </h6>
                                                </div>
                                              </div>

                                              {singlePackage?.order
                                                ?.alternate_recipient && (
                                                <>
                                                  <div className="row mt-5">
                                                    <h5 className="mb-3">
                                                      Alternate Recipient
                                                    </h5>
                                                    <hr />
                                                    <div className="row">
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          Name
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.name
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          Phone
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.phone
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          Address
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.address
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          Notes
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.notes
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          LGA
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.local_government
                                                          }
                                                        </h6>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <span className="price-head text-green">
                                                          State
                                                        </span>
                                                        <h6 className="details">
                                                          {
                                                            singlePackage?.order
                                                              ?.alternate_recipient
                                                              ?.state
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  acceptPackage(
                                                    singlePackage?.uuid
                                                  )
                                                }}
                                              >
                                                Accept Package
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <h5>No incoming orders</h5>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Orders
