import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../referrals/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Link, Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"

const GroupedPackages = () => {
  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [singlePackage, setSinglePackage] = useState({})
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [searchResult, setSearchResult] = useState([])
  const [perPage, setPerPage] = useState(50)
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -365),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = singlePackage => {
    setSinglePackage(singlePackage)
  }

  // package Movement

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-groups?perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        )
        const packages = await response.json()
        console.log(packages)
        console.log(packages.data.package_groups)
        setPackages(packages.data.package_groups)
        setLoading(false)
        setLastPage(packages.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        // window.location.href = '/login'
      }
    }

    fetchPackage()
  }, [perPage, next, sort, minDate, maxDate])

  // Search orders
  const searchOrder = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/search`,
        options
      )
      const data = await response.json()
      console.log(data.data)
      setSearchResult(data["data"]["orders"])
      if (data.status == "success") {
        console.log(data["message"])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bagged Packages | Dilivva Swifthub Dashboard</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Bagged Packages</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search orders by name or reference"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={() => searchOrder()}
            />
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { reference, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/order/" + uuid)}
                  >
                    {reference}
                  </button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Row className="mt-2">
                        <Col md={9}>
                          <h4 className="title-header">Bagged Packages</h4>
                        </Col>
                        {packages?.length !== 0 && (
                          <>
                            <Col md={1} xs={8}>
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={perPage}
                                onChange={e => {
                                  setLoading(true)
                                  setPerPage(e.target.value)
                                }}
                              >
                                <option defaultValue>50</option>
                                <option defaultValue="25">25</option>
                                <option defaultValue="10">10</option>
                                <option defaultValue="5">5</option>
                              </select>
                            </Col>
                            <Col md={2} xs={8}>
                              <Row className="">
                                <Col>
                                  <FaArrowUp
                                    className="sort"
                                    onClick={() => setSort("asc")}
                                  />{" "}
                                  <FaArrowDown
                                    className="sort"
                                    onClick={() => setSort("desc")}
                                  />{" "}
                                  Sort
                                </Col>
                                <Col
                                  className="sort"
                                  onClick={() => setShowDate(!showDate)}
                                >
                                  <FaFilter /> Filter
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                      </Row>
                      {packages?.length !== 0 ? (
                        <>
                          <Row>
                            <Col>
                              {showDate && (
                                <DateRangePicker
                                  onChange={item =>
                                    setDateRange([item.selection])
                                  }
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={dateRange}
                                  direction="horizontal"
                                />
                              )}
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <Table className="table responsive table-hover mt-2 mb-0">
                              <thead>
                                <tr>
                                  <th>Final Hub</th>
                                  <th>Location</th>
                                  <th>Number of Items</th>
                                  <th>Reference</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages?.map((item, index) => {
                                  const {
                                    uuid,
                                    status,
                                    reference,
                                    package_movements,
                                  } = item
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        onClick={() =>
                                          setRedirect(
                                            `grouped-packages/${uuid}`
                                          )
                                        }
                                      >
                                        {package_movements
                                          ?.slice(0, 1)
                                          ?.map(item => (
                                            <td key={item.uuid}>
                                              {item?.final_hub?.address}
                                            </td>
                                          ))}
                                        {package_movements
                                          ?.slice(0, 1)
                                          ?.map(item => (
                                            <td key={item.uuid}>
                                              {item?.final_hub?.location?.name}
                                            </td>
                                          ))}
                                        <td>{package_movements.length}</td>
                                        <td>{reference}</td>
                                        <td
                                          style={{
                                            color:
                                              status === "accepted"
                                                ? "#29CC97"
                                                : status === "completed"
                                                ? "#25b182"
                                                : status === "cancelled"
                                                ? "#DC1515"
                                                : "#FEC400",
                                          }}
                                        >
                                          {status}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-3">
                          <h4>Oops, No orders</h4>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GroupedPackages
