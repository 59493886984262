import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import "./orders.css"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { Link } from "react-router-dom"
import { BsArrowLeft } from "react-icons/bs"
import baseURL from "../../utils"
import { ToastContainer, toast } from "react-toastify"

const GroupedDetails = props => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [singlePackage, setSinglePackage] = useState({})
  const [collectCode, setCollectCode] = useState("")
  const [disabled, setDisabled] = useState(true)

  const showModal = singlePackage => {
    setSinglePackage(singlePackage)
  }

  // Order Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/hub-managers/package-groups/${props?.match?.params?.id}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data, "orders")
        setLoading(false)
        setOrders(orders.data)
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // handle input change
  const handleChange = e => {
    const re = /^[0-9\b]+$/

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setCollectCode(e.target.value)
    }
    if (collectCode.length < 5) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  // verify collect code
  const verifyCollectCode = async () => {
    setDisabled(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          collect_code: collectCode,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/hub-managers/package-groups/${props?.match?.params?.id}/verify-collect-code`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        console.log(data)
        setTimeout(() => {
          location.reload()
        }, 3000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setDisabled(false)
      }
    } catch (error) {
      // do what
      toast.error(error.message)
      setDisabled(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Grouped Package Details | Swifthub Admin</title>
        </MetaTags>
        {loading ? (
          <div className="container">
            <div className="row mx-md-3">
              <div className="col-md-2 skeleton skeleton-head p-3 mt-1 mb-3"></div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <Link
                to="/grouped-packages"
                className="mb-4 mx-md-4 btn btn-secondary"
              >
                <BsArrowLeft /> <span className="mx-2">back</span>
              </Link>
            </div>
            <div className="container">
              <ToastContainer />
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h6>Package Details</h6>
                    <hr />
                    <div className="row">
                      {orders?.package_movements?.map(item => {
                        return (
                          <div className="col-md-2 mb-3">
                            <img
                              src={item?.order?.package?.images[0]}
                              className="img-fluid rounded"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                              alt="package-img"
                            />
                            <p className="m-2 price-head">
                              {item?.order?.package?.label}
                            </p>
                            <button
                              className="btn btn-success"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => {
                                showModal(item)
                              }}
                            >
                              View Details
                            </button>

                            {/* Modal */}
                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-xl rounded">
                                <div className="modal-content px-4 border-0">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {singlePackage?.order?.package?.label}
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row p-2 mb-3">
                                      <div className="col-md-6 mb-4">
                                        <img
                                          src={
                                            singlePackage?.order?.package
                                              ?.images[0]
                                          }
                                          className="img-fluid"
                                          style={{ width: "300px" }}
                                          alt="package-img"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <span className="price-head text-green">
                                          Label
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.package?.label}
                                        </h6>
                                      </div>
                                      <div className="col">
                                        <span className="price-head text-green">
                                          Reference
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.package
                                              ?.reference
                                          }
                                        </h6>
                                      </div>
                                      <div className="col">
                                        <span className="price-head text-green">
                                          Size
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.package?.size}
                                        </h6>
                                      </div>
                                      {singlePackage?.order?.package?.note && (
                                        <div className="col">
                                          <span className="price-head text-green">
                                            Note
                                          </span>
                                          <h6 className="details">
                                            {
                                              singlePackage?.order?.package
                                                ?.note
                                            }
                                          </h6>
                                        </div>
                                      )}
                                      <div className="col">
                                        <span className="price-head text-green">
                                          Fragile
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.package
                                            ?.is_fragile === 0
                                            ? "No"
                                            : "Yes"}
                                        </h6>
                                      </div>
                                    </div>

                                    <h5 className="mt-5">Order Details</h5>
                                    <hr />
                                    <div className="row">
                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Pickup Address
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.pickup_address}
                                        </h6>
                                      </div>
                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Destination Address
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order
                                              ?.destination_address
                                          }
                                        </h6>
                                      </div>
                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Recommended Hub
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.recommended_hub
                                            ? singlePackage?.recommended_hub
                                                ?.address
                                            : "None"}
                                        </h6>
                                      </div>
                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Final Hub
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.final_hub
                                            ? singlePackage?.final_hub?.address
                                            : "None"}
                                        </h6>
                                      </div>

                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Date Created
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.created_at}
                                        </h6>
                                      </div>
                                      <div className="col-md-4">
                                        <span className="price-head text-green">
                                          Reference
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.reference}
                                        </h6>
                                      </div>
                                    </div>

                                    {singlePackage?.order?.pickup_contact && (
                                      <>
                                        <div className="row mt-5">
                                          <h5>Pickup Contact</h5>
                                          <hr />
                                          <div className="row">
                                            <div className="col">
                                              <span className="price-head text-green">
                                                Name
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.pickup_contact?.name
                                                }
                                              </h6>
                                            </div>
                                            <div className="col">
                                              <span className="price-head text-green">
                                                Email
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.pickup_contact?.email
                                                }
                                              </h6>
                                            </div>
                                            <div className="col">
                                              <span className="price-head text-green">
                                                Phone
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.pickup_contact?.phone
                                                }
                                              </h6>
                                            </div>
                                            <div className="col">
                                              <span className="price-head text-green">
                                                Date Created
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.pickup_contact?.created_at
                                                }
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <h5 className="mt-5">Sender</h5>
                                    <hr />
                                    <div className="row">
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Name
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.user
                                              ?.full_name
                                          }
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Email
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.user?.email}
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Phone
                                        </span>
                                        <h6 className="details">
                                          {singlePackage?.order?.user?.phone}
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Date Created
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.user
                                              ?.created_at
                                          }
                                        </h6>
                                      </div>
                                    </div>

                                    <h5 className="mt-5">
                                      Recipient(
                                      {singlePackage?.order?.recipient
                                        ?.is_user === true
                                        ? "sender"
                                        : "Not a sender"}
                                      )
                                    </h5>
                                    <hr />
                                    <div className="row">
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Name
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.recipient
                                              ?.name
                                          }
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Email
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.recipient
                                              ?.email
                                          }
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Phone
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.recipient
                                              ?.phone
                                          }
                                        </h6>
                                      </div>
                                      <div className="col-md-3">
                                        <span className="price-head text-green">
                                          Date Created
                                        </span>
                                        <h6 className="details">
                                          {
                                            singlePackage?.order?.recipient
                                              ?.created_at
                                          }
                                        </h6>
                                      </div>
                                    </div>

                                    {singlePackage?.order
                                      ?.alternate_recipient && (
                                      <>
                                        <div className="row mt-5">
                                          <h5 className="mb-3">
                                            Alternate Recipient
                                          </h5>
                                          <hr />
                                          <div className="row">
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                Name
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient?.name
                                                }
                                              </h6>
                                            </div>
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                Phone
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient?.phone
                                                }
                                              </h6>
                                            </div>
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                Address
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient
                                                    ?.address
                                                }
                                              </h6>
                                            </div>
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                Notes
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient?.notes
                                                }
                                              </h6>
                                            </div>
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                LGA
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient
                                                    ?.local_government
                                                }
                                              </h6>
                                            </div>
                                            <div className="col-md-3">
                                              <span className="price-head text-green">
                                                State
                                              </span>
                                              <h6 className="details">
                                                {
                                                  singlePackage?.order
                                                    ?.alternate_recipient?.state
                                                }
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="row mt-5">
                      {orders?.status === "pending" && (
                        <div className="col-md-6 mb-4">
                          <h6>Verify Package</h6>
                          <hr />
                          <input
                            type="text"
                            value={collectCode}
                            onChange={handleChange}
                            className="form-control mb-2"
                            maxLength={6}
                            placeholder="Enter the collect code"
                            required
                          />
                          <button
                            className="btn btn-success"
                            onClick={() => verifyCollectCode()}
                            disabled={disabled}
                          >
                            verify package
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mx-md-3">
                <div className="card">
                  <div className="card-body">
                    <h6>Delivery Hub Details</h6>
                    <hr />
                    <div className="row">
                      <div className="col-md-4">
                        <span className="price-head text-green">
                          Hub Address
                        </span>
                        <h6 className="details">
                          {orders?.delivery_hub?.address}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Location</span>
                        <h6 className="details">
                          {orders?.delivery_hub?.location?.name}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Manager</span>
                        <h6 className="details">
                          {orders?.delivery_hub?.manager?.first_name}{" "}
                          {orders?.delivery_hub?.manager?.last_name}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <span className="price-head text-green">Email</span>
                        <h6 className="details">
                          {orders?.delivery_hub?.manager?.email}
                        </h6>
                      </div>

                      <div className="col-md-4">
                        <span className="price-head text-green">phone</span>
                        <h6 className="details">
                          {orders?.delivery_hub?.manager?.phone}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default GroupedDetails
